import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import googleTagManager from "@analytics/google-tag-manager";
import { isLiveEnv } from "./environment";
import logrocketPlugin from "./plugins/logrocket/index";

const googleAnalyticsPlugin = googleAnalytics({
  measurementIds: ["G-PMEW5CDHC1"],
  debug: !isLiveEnv,
});

const logrocket = logrocketPlugin({
  appId: process.env.NEXT_PUBLIC_LOGROCKET_KEY,
});

const campaignDataPlugin = {
  name: "customDataPlugin",
  // Custom functions to expose to analytics instance
  methods: {
    trackCampaignData(payload) {
      const { mailingId, campaignName, source, medium } = payload?.traits || {};
      if (gtag) {
        gtag("set", {
          campaign_id: mailingId,
          campaign_name: campaignName,
          campaign_source: source ? source : "(direct)",
          campaign_medium: medium ? medium : "organic",
        });
      }
    },
  },
};

const googleTagManagerPlugin = googleTagManager({
  containerId: "GTM-MSGMG2JQ",
});

// TODO Enable when cookie preference is ready to be deployed
const isTrackingCookieAccepted = "true"; // getItem("acceptsCookie") === "true";
const plugins = isTrackingCookieAccepted
  ? [
      googleAnalyticsPlugin,
      googleTagManagerPlugin,
      campaignDataPlugin,
      logrocket,
    ]
  : [];

const analytics = Analytics({
  app: "pronto",
  plugins: plugins,
});

export default analytics;
