import { v1 } from "uuid";
import { GA4_PURCHASE, GA4_SHARE } from "./helpers";
import analytics from "../../lib/analytics";

export const logGa4Ecommerce = (data) => {
  const uuid = v1();
  const { amount, currency, recurring, pageType, gaItem, slug } = data;
  const price = parseFloat(amount).toFixed(2);
  analytics.track(GA4_PURCHASE, {
    transaction_id: uuid,
    value: price,
    currency,
    slug,
    action_page_type: pageType,
    items: [
      {
        item_id: gaItem?.id,
        item_name: gaItem?.id,
        item_list_name: gaItem?.listName,
        item_variant: recurring,
        item_category: pageType,
        price,
        quantity: 1,
      },
    ],
  });
};

export const logShareToGA = (data) => {
  const { type, slug, pageType } = data;
  analytics.track(GA4_SHARE, {
    content_type: "link",
    method: getShareType(type),
    slug,
    action_page_type: pageType,
  });
};

const getShareType = (shareType) => {
  switch (shareType) {
    case "f":
      return "facebook";
    case "t":
      return "twitter";
    case "e":
      return "email";
    case "w":
      return "whatsapp";
    default:
      return "other";
  }
};

export const measurementId = "G-PMEW5CDHC1";
